import { useState } from 'react';
import { checkValidity, updatedObject } from '../../shared/utility'
import ButtonLink from '../Button/ButtonLink/ButtonLink';
import Spinner from '../Spinner/Spinner';
import styles from './contact.module.css';
const Contact = () => {
    // app states
    const [contactForm, setContactForm] = useState({
        name: {
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false

        },
        email: {
            value: '',
            validation: {
                required: true,
                validEmail: true
            },
            valid: false,
            touched: false
        },
        message: {
            value: '',
            validation: {
                required: true,
                validMessage: true
            },
            valid: false,
            touched: false
        }
    });
    const [formIsValid, setFormIsValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sucessMessage, setSuccessMessage] = useState("");

    // called when the form is submitted.
    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        contact();
    };

    // handles the onchange event of the form inputs
    const handleChange = (event) => {
        event.persist();
        const inputIdentifier = event.target.name;

        const updatedFormElement = updatedObject(contactForm[inputIdentifier], {
            value: inputIdentifier === 'emailConsent' ? event.target.checked : event.target.value,
            valid: checkValidity(inputIdentifier === 'emailConsent' ? event.target.checked : event.target.value, contactForm[inputIdentifier].validation),
            touched: true
        })
        const updatedForm = updatedObject(contactForm, {
            [inputIdentifier]: updatedFormElement
        });
        let formIsValid = true;
        for (let inputIdentifier in updatedForm) {
            formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
        }
        setContactForm(updatedForm);
        setFormIsValid(formIsValid);

    };

    // clear values
    const clearForm = () => {
        setContactForm({
            name: {
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false

            },
            email: {
                value: '',
                validation: {
                    required: true,
                    validEmail: true
                },
                valid: false,
                touched: false
            },
            message: {
                value: '',
                validation: {
                    required: true,
                    validMessage: true
                },
                valid: false,
                touched: false
            }
        });
    }

    // makes an api call once there are no form errors
    const contact = async () => {
        const userData = {};
        for (let formElementIdentifier in contactForm) {
            userData[formElementIdentifier] = contactForm[formElementIdentifier].value;
        }
        try {
            setLoading(true)
            const response = await fetch('https://my-json-server.typicode.com/JustUtahCoders/interview-users-api/users', {
                crossDomain: true,
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify([{ id: 1, ...userData }])
            });
            const data = await response.json();
            setSuccessMessage("Thanks for your message.")
            setLoading(false)
            // Clear the form
            clearForm();
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div className={styles.contact} id="contact">
            {loading && <Spinner />}
            {sucessMessage && <p className={styles.successMessage}>{sucessMessage}</p>}
            <h2 className="sectionTitle">Contact</h2>
            <form onSubmit={handleSubmit}>
                <div className={styles.formGroup}>
                    <label htmlFor="name">Name</label>
                    <input className={!contactForm.name.valid && contactForm.name.touched ? 'invalid' : undefined}
                        type="text"
                        name="name"
                        id="name"
                        onChange={handleChange} value={contactForm.name.value} />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="email">Email</label>
                    <input className={!contactForm.email.valid && contactForm.email.touched ? 'invalid' : undefined}
                        type="email"
                        name="email"
                        id="email"
                        onChange={handleChange} value={contactForm.email.value} />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="message">Message</label>
                    <textarea className={!contactForm.message.valid && contactForm.message.touched ? 'invalid' : undefined}
                        id="message"
                        name="message"
                        rows="4"
                        cols="50"
                        onChange={handleChange}
                        value={contactForm.message.value}>
                    </textarea>
                </div>
                <div className={styles.formGroup}>
                    <input className={[styles.button, formIsValid && styles['submitButton']].join(' ')} type="submit" value="Send" disabled={!formIsValid} />
                </div>
            </form>
            <div className={styles.contactSocialMedia}>
                <div className={styles.socialMedia}>
                    <ButtonLink link="https://www.linkedin.com/in/clement-mwimo-6b5287b3/">
                        <img src={`assets/icons/linkedin.png`} alt="linkedInIcon" />
                    </ButtonLink>
                    <ButtonLink link="https://github.com/mwclemy">
                        <img src={`assets/icons/github.png`} alt="githubIcon" />
                    </ButtonLink>
                    <ButtonLink link="mailto:mwimoclement@gmail.com">
                        <img src={`assets/icons/email.png`} alt="emailIcon" />
                    </ButtonLink>
                </div>
                <p>Clement {new Date().getFullYear()}</p>
            </div>
        </div>
    )
}

export default Contact;