import NavigationItem from './NavigationItem/NavigationItem'
import styles from './navigation-items.module.css';
const NavigationItems = () => (
    <ul className={styles.navItems}>
        <NavigationItem link='about'>About</NavigationItem>
        <NavigationItem link='projects'>Projects</NavigationItem>
        <NavigationItem link='tools'>Tools</NavigationItem>
        <NavigationItem link='contact'>Contact</NavigationItem>
    </ul>
);
export default NavigationItems;