import { useState } from 'react'
import ToolBar from './components/Navigation/ToolBar/ToolBar'
import SideDrawer from './components/SideDrawer/SideDrawer'
import './App.css';
import Home from './components/Home/Home';
import Project from './components/Project/Project';
import ToolSection from './components/ToolSection/ToolSection';
import Contact from './components/Contact/Contact';
function App() {
  const [sideDrawerIsVisible, setSideDrawerIsVisible] = useState(false);

  const toolSections = [
    {
      sectionTitle: "Application and data",
      tools: ["jquery", "python", "nodejs", "react", "nginx", "java", "django", "mysql", "postgresql", "mongoDB"]
    },
    {
      sectionTitle: "DevOps",
      tools: ["docker", "vagrant", "git", "gitlabCI", "digitalOcean", "firebase"]
    },

    {
      sectionTitle: "Utilities",
      tools: ["googleAnalytics", "twilio", "apacheThrift", "stripe", "plaid", "amazonS3"]
    },

    {
      sectionTitle: "Business Tools",
      tools: ["slack", "trello", "asana", "figma"]
    }
  ]

  const sideDrawerClosedHandler = () => {
    setSideDrawerIsVisible(false);
  }

  const sideDrawerToggleHandler = () => {
    setSideDrawerIsVisible(!sideDrawerIsVisible);
  }
  return (
    <div className="App">
      <ToolBar drawerToggleClicked={sideDrawerToggleHandler} />
      <SideDrawer
        open={sideDrawerIsVisible}
        closed={sideDrawerClosedHandler} />
      <main className="content">
        <Home />
        <div id="projects">
          <h2 className="sectionTitle">Projects</h2>
          <div className="allProjects">
            <Project imgName="project1"
              name="Kuickad"
              description="Kuickad is an online platform that allows business owners or individuals to place ads to different mediahouses like TVs or Radios"
              liveLink="https://staging.kuickad.com/" />
            <Project imgName="project2"
              name="NotifyMe"
              description="NotifyMe keeps all your accounts transactions in one place and notifies you whenever you exceed a given amount."
              liveLink="https://notify-me-frontend.herokuapp.com/"
              githubLink="https://github.com/mwclemy/notifyme_frontend" />
          </div>
        </div>
        <div id="tools">
          <h2 className="sectionTitle">Tools</h2>
          {toolSections.map(toolSection => {
            return <ToolSection key={toolSection.sectionTitle} sectionTitle={toolSection.sectionTitle} tools={toolSection.tools} />
          })}
        </div>
        <Contact />
      </main>
    </div>
  );
}

export default App;
