import styles from './ToolSection.module.css';
const ToolSection = ({ sectionTitle, tools }) => {
    return <div className={styles.toolSection}>
        <h4>{sectionTitle}</h4>
        <div className={styles.tools}>
            {tools.map(tool => <div className={styles.tool} key={tool}>
                <img src={`assets/icons/${tool}.png`} alt={`${tool}`} />
                <span>{tool}</span>
            </div>)}
        </div>
    </div>
}

export default ToolSection;