import Button from '../Button/Button';
import ButtonLink from '../Button/ButtonLink/ButtonLink';
import styles from './project.module.css'
const Project = ({ imgName, name, description, liveLink, githubLink }) => {
    return (
        <div className={styles.project}>
            <div className={styles.projectIntro}>
                <h3 className={styles.projectName}>{name}</h3>
                <p className={styles.projectDescription}>{description}</p>
                <div className={styles.links}>
                    <ButtonLink link={liveLink}>
                        <Button btnType="secondaryBtn">See live project</Button>
                    </ButtonLink>
                    {githubLink && <ButtonLink link={githubLink}>
                        <img src={`assets/icons/github.png`} alt="githubIcon" />
                    </ButtonLink>}
                </div>
            </div>
            <img src={`assets/images/${imgName}.png`} alt="projectImage" />
        </div>
    )
}

export default Project;