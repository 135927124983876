import styles from './home.module.css';
import Button from '../Button/Button';
import { Link } from "react-scroll";
import ButtonLink from '../Button/ButtonLink/ButtonLink';
const Home = () => {
    return (
        <div className={styles.home} id="about">
            <div className={styles.intro}>
                <h3 className={styles.position}>FULL-STACK SOFTWARE ENGINEER</h3>
                <h2 className={styles.title}>Hello, Welcome to my Site :)</h2>
                <p className={styles.summary}>
                    Focused and quick-learning Software Engineer with more than 3 years of experience in software engineering and web development. Able to effectively self-manage during independent projects, as well as collaborate as part of a productive team.
            </p>
                <div className={styles.introBtns}>
                    <Link
                        to="projects"
                        activeClass={styles.active}
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}>
                        <Button btnType="primaryBtn">Projects</Button>
                    </Link>
                    <ButtonLink link="/assets/files/ClementMwimo_Resume1.pdf" btnType="download">
                        <Button btnType="secondaryBtn">Download CV</Button>
                    </ButtonLink>
                </div>
            </div>
            <img className={styles.myPhoto} src={`/assets/images/myphoto.png`} alt="myphoto" />
        </div>
    )
}

export default Home;