import NavigationItems from '../Navigation/NavigationItems/NavigationItems';
import styles from './side-drawer.module.css'
const SideDrawer = (props) => {
    let attachedClasses = [styles.sideDrawer, styles.open];
    if (!props.open) {
        attachedClasses = [styles.sideDrawer, styles.close];
    }
    return (
        <>
            <div className={attachedClasses.join(' ')} onClick={props.closed}>
                <div className={styles.sideDrawerHeader}>
                    {props.open ?
                        <img className={styles.closeButton} src={`/assets/icons/closeicon.svg`} alt="closeicon" />
                        : null}
                </div>
                <nav>
                    <NavigationItems />
                </nav>
            </div>
        </>
    );
}

export default SideDrawer;