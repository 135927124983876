import React from 'react';
import styles from './navigation-item.module.css';
import { Link } from "react-scroll";
const NavigationItem = (props) => {
    let attachedClasses = [styles.navItem];
    return <li className={attachedClasses.join(' ')}>
        <Link
            to={props.link}
            activeClass={styles.active}
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}>
            {props.children}

        </Link>
    </li>
}
export default NavigationItem;