const ButtonLink = ({ link, btnType, children }) => {

    return btnType === "download" ?
        <a href={link} download>
            {children}
        </a> :
        <a href={link} target="_blank" rel="noreferrer noopener">
            {children}
        </a>
}
export default ButtonLink;